exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-azienda-js": () => import("./../../../src/pages/azienda.js" /* webpackChunkName: "component---src-pages-azienda-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-src-news-2023-10-automiotive-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/riccardosirigu/Desktop/Abissi Website/abissi-website/src/news/2023-10-automiotive/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-src-news-2023-10-automiotive-index-mdx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-prodotti-deep-fisher-js": () => import("./../../../src/pages/prodotti/deep-fisher.js" /* webpackChunkName: "component---src-pages-prodotti-deep-fisher-js" */),
  "component---src-pages-servizi-js": () => import("./../../../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */)
}

